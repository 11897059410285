// import sayHello from './lib/sayHello.js';
import './lib/common.js';
import Rellax from 'rellax';

// sayHello();


var rellax = new Rellax('.rellax');

$('.show-faq-link').on('click', function(e) {
  e.preventDefault();
  $('.faq').addClass('shown');
  $(this).remove();
});
